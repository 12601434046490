<template>
  <b-container class="page-container">
    <bread-crumbs currentpage="Registreren" />
    <h1>Registreren</h1>

    <p>Vul uw gegevens in op het onderstaande formulier voor het registeren.</p>

    <b-row>
      <b-col md="10" sm="12">
        <b-card>
          <b-form @submit.prevent="registerUser">
            <b-row>
              <b-col cols="12">
                <h4>Gegevens van uw bedrijf</h4>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-customer-name"
                  label-for="input-customer-name"
                >
                  <label>
                    Naam bedrijf
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-customer-name"
                    v-model="form.companyName"
                    type="text"
                    placeholder="Naam bedrijf"
                    name="companyName"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-customer-organisation-number"
                  label-for="input-customer-organisation-number"
                >
                  <label>Ondernemingsnummer</label>
                  <b-form-input
                    id="input-customer-organisation-number"
                    v-model="form.customerOrganisationNumber"
                    type="text"
                    placeholder="Ondernemingsnummer voor eventuele facturatie"
                    maxlength="30"
                    name="customerOrganisationNumber"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-customer-name"
                  label-for="input-customer-name"
                >
                  <label>
                    Algemeen telefoonnummer
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="tel"
                    v-model="form.tel"
                    type="text"
                    placeholder="Algemeen telefoonnummer"
                    name="tel"
                    maxlength="50"
                    required
                    oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="gsm" label-for="input-customer-name">
                  <label>Algemeen Gsm</label>
                  <b-form-input
                    id="input-customer-name"
                    v-model="form.CUST_MobilePhone"
                    type="text"
                    placeholder="Algemeen telefoonnummer"
                    name="gsm"
                    maxlength="50"
                    oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="12">
                <b-form-group label-for="input-customer-description">
                  <label>
                    Omschrijving van de bedrijf
                    <span class="text-danger">*</span>
                    <br />
                  </label>
                  <b-form-textarea
                    id="input-customer-description"
                    v-model="form.CUST_Notes"
                    placeholder="Omschrijf kort waar de bedrijf voor staat"
                    rows="3"
                    max-rows="6"
                    required
                    maxlength="1000"
                    name="CUST_Notes"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-customer-website"
                  label="Website"
                  label-for="input-customer-website"
                >
                  <b-form-input
                    id="input-customer-website"
                    v-model="form.website"
                    name="website"
                    placeholder="Website"
                    maxlength="255"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="socialmedia"
                  label="Socialmedia"
                  label-for="input-customer-socialmedia"
                >
                  <b-form-input
                    id="input-customer-website"
                    v-model="form.CUST_SocialMedia"
                    name="CUST_SocialMedia"
                    placeholder="Socialmedia"
                    maxlength="255"
                    type="text"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="12">
                <b-form-group
                  id="input-group-customer-email"
                  label-for="input-customer-email"
                >
                  <label>
                    E-mailadres bedrijf
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-customer-email"
                    v-model="form.email"
                    type="text"
                    placeholder="E-mailadres"
                    maxlength="255"
                    name="email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-street" label-for="input-street">
                  <label>
                    Straat
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-street"
                    v-model="form.street"
                    type="text"
                    placeholder="Straat"
                    maxlength="50"
                    name="street"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="6">
                <b-form-group
                  id="input-group-housenumber"
                  label-for="input-housenumber"
                >
                  <label>
                    Huisnummer
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-housenumber"
                    v-model="form.houseNumber"
                    type="text"
                    placeholder="Huisnummer"
                    maxlength="10"
                    name="houseNumber"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group id="input-group-postal" label-for="input-postal">
                  <label>
                    Postcode
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-postal"
                    v-model="form.postcode"
                    type="text"
                    placeholder="Postcode"
                    maxlength="10"
                    name="postcode"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-city" label-for="input-city">
                  <label>
                    Stad
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-city"
                    v-model="form.city"
                    type="text"
                    placeholder="Stad"
                    name="city"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <hr />
                <h4>Contact gegevens hoofdverantwoordelijke</h4>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-firstname"
                  label-for="input-firstname"
                >
                  <label>
                    Voornaam
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-firstname"
                    v-model="form.firstname"
                    type="text"
                    placeholder="Voornaam"
                    name="firstname"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-lastname"
                  label-for="input-lastname"
                >
                  <label>
                    Achternaam
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-lastname"
                    v-model="form.lastname"
                    type="text"
                    placeholder="Achternaam"
                    name="lastname"
                    maxlength="50"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-tel" label-for="input-tel">
                  <label>
                    Gsm
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-tel"
                    v-model="form.CONTACT_MobilePhone"
                    type="text"
                    placeholder="Gsm"
                    name="mobiletel"
                    maxlength="50"
                    required
                    oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group id="input-group-tel" label-for="input-tel">
                  <label>Telefoon</label>
                  <b-form-input
                    id="input-tel"
                    v-model="form.CONTACT_Tel"
                    maxlength="50"
                    type="text"
                    name="CONTACT_Tel"
                    placeholder="Telefoonnummer"
                    oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col sm="12" md="6">
                <b-form-group id="input-group-email" label-for="input-email">
                  <label>
                    E-mailadres (Gebruikt bij het inloggen)
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    id="input-email"
                    v-model="form.contactEmail"
                    maxlength="250"
                    type="email"
                    name="contactEmail"
                    placeholder="E-mailadres"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <hr />
                <b-form-text id="password-help-block">
                  <p>
                    Een nieuwe wachtwoord moet aan de volgende eisen voldoen:
                  </p>
                  <ul>
                    <li>- Minimaal 8 karakters zijn.</li>
                    <li>- Met minimaal één hoofdletter. A t/m Z.</li>
                    <li>- Met minimaal één kleine letter. a t/m z.</li>
                    <li>- Met minimaal één cijfer. 0 t/m 9.</li>
                    <li>- Met minimaal één speciale teken, keuze uit !@$%&</li>
                  </ul>
                </b-form-text>
              </b-col>
              <b-col sm="12" md="6">
                <span
                  v-if="!passwordValidation && form.password.length > 0"
                  class="text-danger"
                  >Uw wachtwoord voldoet niet aan de eisen</span
                >
                <br v-if="passwordValidation || form.password.length === 0" />
                <b-form-group
                  id="input-group-password"
                  label-for="input-password"
                >
                  <label>
                    Wachtwoord
                    <span class="text-danger">*</span>
                  </label>
                  <b-input-group>
                    <b-form-input
                      id="input-password"
                      v-model="form.password"
                      :type="showPassword ? 'text' : 'password'"
                      :state="passwordValidation"
                      placeholder="Wachtwoord"
                      name="password"
                      required
                      min="8"
                      max="32"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        title="Wachtwoord tonen"
                        variant="primary"
                        @click="showPasswordToggle()"
                      >
                        <font-awesome-icon
                          v-if="!showPassword"
                          fas
                          icon="eye"
                        />
                        <font-awesome-icon
                          v-if="showPassword"
                          fas
                          icon="eye-slash"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-passwordConfirm"
                  label-for="input-passwordConfirm"
                >
                  <span
                    v-if="
                      !passwordConfirmState && form.confirmPassword.length > 0
                    "
                    class="text-danger"
                    >De wachtwoorden komen niet overeen</span
                  >
                  <br
                    v-if="
                      passwordConfirmState || form.confirmPassword.length === 0
                    "
                  />
                  <label>
                    Herhaal wachtwoord
                    <span class="text-danger">*</span>
                  </label>
                  <b-input-group>
                    <b-form-input
                      id="input-passwordConfirm"
                      v-model="form.confirmPassword"
                      :type="showPassword ? 'text' : 'password'"
                      :state="passwordConfirmState && passwordValidation"
                      name="confirmPassword"
                      placeholder="Vul uw wachtwoord nogmaals in"
                      required
                      min="8"
                      max="32"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        title="Wachtwoord tonen"
                        variant="primary"
                        @click="showPasswordToggle()"
                      >
                        <font-awesome-icon
                          v-if="!showPassword"
                          fas
                          icon="eye"
                        />
                        <font-awesome-icon
                          v-if="showPassword"
                          fas
                          icon="eye-slash"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- Invoice details -->
              <b-col cols="12">
                <hr />
                <h4>Facturatiegegevens</h4>
              </b-col>
              <b-col cols="12">
                <b-form-checkbox
                  id="invoiceSameAsOrganisationAddress"
                  v-model="invoiceSameAsOrganisationAddress"
                  name="invoiceSameAsOrganisationAddress"
                >
                  Factuurgegevens zijn dezelfde als de bedrijfgegevens
                </b-form-checkbox>
              </b-col>
              <b-col v-if="!invoiceSameAsOrganisationAddress" cols="12">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-customer-name"
                      label-for="input-customer-name"
                    >
                      <label>
                        Naam bedrijf
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-companyname"
                        v-model="form.InvoiceCompanyName"
                        type="text"
                        maxlength="50"
                        max="50"
                        placeholder="Naam bedrijf"
                        :required="!invoiceSameAsOrganisationAddress"
                        name="InvoiceCompanyName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-customer-invoice-vatnumber"
                      label-for="input-customer-invoice-vatnumber"
                    >
                      <label>Ondernemingsnummer</label>
                      <b-form-input
                        id="input-customer-invoice-vatnumber"
                        v-model="form.VATNo"
                        type="text"
                        maxlength="30"
                        placeholder="Ondernemingsnummer voor eventuele facturatie"
                        name="invoiceVatnumber"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="12">
                    <b-form-group
                      id="input-group-customer-invoice-firstname"
                      label-for="input-customer-invoice-firstname"
                    >
                      <label>
                        Voornaam
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-firstname"
                        v-model="form.InvoiceFirstName"
                        type="text"
                        placeholder="Voornaam"
                        maxlength="50"
                        :required="!invoiceSameAsOrganisationAddress"
                        name="InvoiceFirstName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="12">
                    <b-form-group
                      id="input-group-customer-invoice-lastname"
                      label-for="input-customer-invoice-lastname"
                    >
                      <label>
                        Achternaam
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-customer-invoice-lastname"
                        v-model="form.InvoiceLastName"
                        type="text"
                        placeholder="Achternaam"
                        maxlength="50"
                        :required="!invoiceSameAsOrganisationAddress"
                        name="InvoiceLastName"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-street"
                      label-for="input-invoice-street"
                    >
                      <label>
                        Straat
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-street"
                        v-model="form.InvoiceStreet"
                        type="text"
                        placeholder="Straat"
                        maxlength="50"
                        :required="!invoiceSameAsOrganisationAddress"
                        name="InvoiceStreet"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" md="6">
                    <b-form-group
                      id="input-group-invoice-housenumber"
                      label-for="input-invoice-housenumber"
                    >
                      <label>
                        Huisnummer
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-housenumber"
                        v-model="form.InvoiceHouseNumber"
                        type="text"
                        placeholder="Huisnummer"
                        maxlength="10"
                        :required="!invoiceSameAsOrganisationAddress"
                        name="InvoiceHouseNumber"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-postal"
                      label-for="input-invoice-postal"
                    >
                      <label>
                        Postcode
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-postal"
                        v-model="form.InvoiceZipCode"
                        type="text"
                        maxlength="10"
                        placeholder="Postcode"
                        :required="!invoiceSameAsOrganisationAddress"
                        name="InvoiceZipCode"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-city"
                      label-for="input-city"
                    >
                      <label>
                        Stad
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-city"
                        v-model="form.InvoiceCity"
                        type="text"
                        placeholder="Stad"
                        maxlength="50"
                        :required="!invoiceSameAsOrganisationAddress"
                        name="InvoiceCity"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-phone"
                      label-for="input-phone"
                    >
                      <label>
                        Telefoonnummer
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-city"
                        v-model="form.InvoiceTel"
                        type="text"
                        maxlength="20"
                        placeholder="Telefoon nummer"
                        oninput="javascript: this.value = this.value.replace(/[A-Za-z]/gi, '');"
                        :required="!invoiceSameAsOrganisationAddress"
                        name="InvoiceTel"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      id="input-group-invoice-email"
                      label-for="input-email"
                    >
                      <label>
                        E-mail address
                        <span class="text-danger">*</span>
                      </label>
                      <b-form-input
                        id="input-invoice-email"
                        v-model="form.InvoiceEmail"
                        type="text"
                        maxlength="250"
                        name="InvoiceEmail"
                        placeholder="E-mail address"
                        :required="!invoiceSameAsOrganisationAddress"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <!-- END invoice details -->

              <b-col>
                <hr />
              </b-col>
              <b-col sm="12" md="12">
                <b-form-group
                  id="input-group-customer-description"
                  label-for="input-customer-description"
                >
                  <label>Opmerking</label>
                  <b-form-textarea
                    id="input-customer-description"
                    v-model="form.CUST_Comments"
                    placeholder="Eventuele opmerkingen"
                    maxlength="1000"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>

              <b-col cols="12" class="text-right">
                <b-alert
                  v-if="errorMessage"
                  show
                  variant="danger"
                  class="mt-3 text-left"
                  >{{ errorMessage }}</b-alert
                >
                <b-button
                  class="mb-2"
                  type="submit"
                  variant="primary"
                  :disabled="registering"
                >
                  Registreren
                  <font-awesome-icon
                    v-if="registering"
                    class="fa-spin"
                    far
                    :icon="['fad', 'spinner']"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
const { register } = require('@/services/AuthorizationService')

export default {
  data() {
    return {
      form: {
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        street: '',
        houseNumber: '',
        houseNumberAddition: '',
        postcode: '',
        city: '',
        tel: '',
        CUST_MobilePhone: '',
        companyName: '',
        customerOrganisationNumber: '',
        vatid: '',
        website: '',
        targetAudience: '',
        firstname: '',
        lastname: '',
        contactEmail: '',
        contactStreet: '',
        contactHouseNumber: '',
        contactPostcode: '',
        contactCity: '',
        CONTACT_Tel: '',
        CONTACT_MobilePhone: '',
        CUST_Notes: '',
        newsletter: false,
        agreedWithTerms: false,
        CUST_SocialMedia: '',

        InvoiceCompanyName: '',
        InvoiceFirstName: '',
        InvoiceLastName: '',
        InvoiceStreet: '',
        InvoiceHouseNumber: '',
        InvoiceHouseNumberAddition: '',
        InvoiceZipCode: '',
        InvoiceCity: '',
        InvoiceTel: '',
        InvoiceEmail: '',
        VATNo: '',
        CUST_Comments: ''
      },
      incorrectPassword: false,
      registering: false,
      error: false,
      errorMessage: null,
      showPassword: false,
      invoiceSameAsOrganisationAddress: true
    }
  },
  metaInfo: {
    title: `Registreren`
  },
  computed: {
    passwordConfirmState() {
      if (this.form.password != '') {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    },
    passwordValidation() {
      let password = this.form.password
      if (password != '') {
        if (password.length < 8) {
          return false
        }

        if (!password.match(/[!@$%&]/)) return false
        if (!password.match(/[0-9]/)) return false
        if (!password.match(/[a-z]/)) return false
        if (!password.match(/[A-Z]/)) return false
        if (!/^([0-9a-zA-Z!@$%&]*)$/.exec(password)) return false

        return true
      }

      return null
    }
  },
  methods: {
    showPasswordToggle: function() {
      if (this.showPassword) {
        this.showPassword = false
      } else {
        this.showPassword = true
      }
    },
    async registerUser(event) {
      this.registering = true
      event.preventDefault()

      if (this.form.password != this.form.confirmPassword) {
        this.registering = false
        return
      }
      if (!this.passwordValidation) {
        this.registering = false
        this.incorrectPassword = true
        return
      }

      if (!this.passwordValidation && !this.loggedIn) {
        this.displayErrorMessage(
          'Het ingevulde wachtwoord voldoet niet aan de vermelde vereisten'
        )
        return
      }

      let InvoiceCompanyName = this.form.InvoiceCompanyName
      let InvoiceStreet = this.form.InvoiceStreet
      let InvoiceHouseNumber = this.form.InvoiceHouseNumber
      let InvoiceHouseNumberAddition = this.form.InvoiceHouseNumberAddition
      let InvoiceZipCode = this.form.InvoiceZipCode
      let InvoiceCity = this.form.InvoiceCity
      let InvoiceTel = this.form.InvoiceTel
      let InvoiceEmail = this.form.InvoiceEmail
      let InvoiceFirstName = this.form.InvoiceFirstName
      let InvoiceLastName = this.form.InvoiceLastName

      if (this.invoiceSameAsOrganisationAddress) {
        InvoiceCompanyName = this.form.companyName
        InvoiceStreet = this.form.street
        InvoiceHouseNumber = this.form.houseNumber
        InvoiceHouseNumberAddition = this.form.houseNumberAddition
        InvoiceZipCode = this.form.postcode
        InvoiceCity = this.form.city
        InvoiceTel = this.form.tel || this.form.mobiletel
        InvoiceEmail = this.form.email
        InvoiceFirstName = this.form.firstname
        InvoiceLastName = this.form.lastname
      }

      const result = await register({
        username: this.form.contactEmail,
        email: this.form.email,
        password: this.form.password,
        street: this.form.street,
        customerOrganisationNumber: this.form.customerOrganisationNumber,
        houseNumber: this.form.houseNumber,
        houseNumberAddition: this.form.houseNumberAddition,
        postcode: this.form.postcode,
        city: this.form.city,
        tel: this.form.tel,
        CUST_MobilePhone: this.form.CUST_MobilePhone,
        companyName: this.form.companyName,
        website: this.form.website,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        CONTACT_email: this.form.contactEmail,
        CONTACT_tel: this.form.CONTACT_Tel,
        CONTACT_MobilePhone: this.form.CONTACT_MobilePhone,
        CUST_SocialMedia: this.form.CUST_SocialMedia,
        CUST_Notes: this.form.CUST_Notes,
        InvoiceCompanyName,
        InvoiceStreet,
        InvoiceHouseNumber,
        InvoiceHouseNumberAddition,
        InvoiceZipCode,
        InvoiceCity,
        InvoiceTel,
        InvoiceEmail,
        InvoiceFirstName,
        InvoiceLastName,
        VATNo: this.form.VATNo,
        CUST_Comments: this.form.CUST_Comments,
        CUST_ReceiveNewsletter: this.form.CUST_ReceiveNewsletter === 'accepted'
      })

      if (
        result.data.Message === 'The entered e-mail address is unauthorized.' ||
        result.data.Message ===
          'This e-mailaddress is already in use by another user.'
      ) {
        this.displayErrorMessage(
          'Het ingevulde emailadres van de gebruiker is reeds gebruikt voor registratie en kan niet worden hergebruikt.'
        )

        return
      }

      if (result.data.Message != 'InvalidModelState' && result.data === 'OK') {
        if (this.loggedIn) {
          this.$router.push({ name: 'accountCreatedExistingAccount' })
        } else {
          this.$router.push({ name: 'accountEmailSend' })
        }
      } else {
        this.displayErrorMessage(
          'Er is iets mis gegaan bij het aanvragen van uw account, probeer het later opnieuw.'
        )
        return
      }

      this.registering = false
    },
    displayErrorMessage: function(errorMessage) {
      this.registering = false
      this.error = true
      this.errorMessage = errorMessage
    }
  }
}
</script>
